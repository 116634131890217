import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Box, CircularProgress } from '@mui/material';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CitationForm from '../components/CitationForm';

import { apiRoute } from '../App';
import { CaseContext } from '../context/CaseContext';
import UserContext from '../context/UserContext';

const HomeView = () => {
    const {
        setAssessFines,
        setAssessFees,
        setAssessTotal,
        setSuspendFees,
        setSuspendFines,
        setSuspendCosts,
        setSuspendTotal,
        setNetFees,
        setNetFines,
        setNetTotal,
        setBalance,
        calculateSums,
        setBalanceRows,
        loading,
        citationView,
        fetchData,
        violationRows,
        setViolationRows,
        setChargesRows,
        setViolationId,
        violationId,
    } = useContext(CaseContext);

    const { allTabs, originalState, setOriginalState, changes, setChanges, modalType, setModalType, changesModal, setChangesModal, handleChangesModal, from, setFrom } = useContext(UserContext);

    const { caseNumber } = useParams();

    const storedTabValue = localStorage.getItem('selectedTab');
    const initialSelectedTab = storedTabValue ? parseInt(storedTabValue) : allTabs ? 3 : 0;
    const [value, setValue] = useState(initialSelectedTab);

    useEffect(() => {
        if (caseNumber) {
            fetchData(caseNumber)
        }
    }, [caseNumber, fetchData]);

    const updateBalanceRows = async () => {
        try {
            const res = await axios.get(`${apiRoute}/api/FeeFineTbls/FkCaseId/${caseNumber}`);
            if (res.data.length === 0) {
                setBalanceRows([]);
                setAssessFines(0);
                setAssessFees(0);
                setAssessTotal(0);
                setSuspendFines(0);
                setSuspendCosts(0);
                setSuspendFees(0);
                setSuspendTotal(0);
                setNetFines(0);
                setNetFees(0);
                setNetTotal(0);
            } else {
                setBalanceRows(res.data);
                const {
                    assessFeeSum,
                    assessFineSum,
                    assessTotalSum,
                    suspendFeeSum,
                    suspendFineSum,
                    suspendCostSum,
                    suspendTotalSum,
                    netFeeSum,
                    netFineSum,
                    netTotalSum,
                } = calculateSums(res.data);

                setAssessFines(assessFineSum);
                setAssessFees(assessFeeSum);
                setAssessTotal(assessTotalSum);
                setSuspendFines(suspendFineSum);
                setSuspendFees(suspendFeeSum);
                setSuspendCosts(suspendCostSum);
                setSuspendTotal(suspendTotalSum);
                setNetFines(netFineSum);
                setNetFees(netFeeSum);
                setNetTotal(netTotalSum);
            }
        } catch (err) {
            console.log("❌ FEE FINE BY CASE ID ERR", err);
            setAssessFines(0);
            setAssessFees(0);
            setAssessTotal(0);
            setSuspendFines(0);
            setSuspendFees(0);
            setSuspendTotal(0);
            setNetFines(0);
            setNetFees(0);
            setNetTotal(0);
        }
    };

    const updateCaseBalance = async () => {
        try {
            const res = await axios.get(`${apiRoute}/api/ViolationViews/ByCaseId/${caseNumber}`)
            const fetchBalances = res.data.map(async item => {
                const row = {
                    ...item,
                    id: item.pkViolationId,
                    balance: 0,
                    disableDelete: false,
                    dueDate: citationView.courtDatetime
                };
                // fetch balance for each row
                try {
                    const ffRes = await axios.get(`${apiRoute}/api/FeeFineTbls/ffTotals/${row.id}/${citationView.pkCitationId}`);
                    let totalBalance = ffRes.data.fineTotal + ffRes.data.costTotal + ffRes.data.feeTotal;
                    // Add the calculated balance to the row
                    row.balance = totalBalance;
                    row.totalCosts = ffRes.data.costTotal;
                    row.totalFees = ffRes.data.feeTotal;
                    row.totalFines = ffRes.data.fineTotal;

                    // api call to fetch all payments for each violation attached to the case
                    const paymentResponse = await axios.get(`${apiRoute}/api/FfPaymentTbls/violationid/${row.id}`);
                    let totalPayments = 0;

                    const bondResponse = await axios.get(`${apiRoute}/api/bond/violation/${row.id}`);

                    row.disableDelete = bondResponse.data;

                    // check to see if there are any payments attached to the violation
                    if (paymentResponse.data.length < 1) {
                        // if there are no payments, set the remaining balance to be the total balance of the violation
                        row.remainingBalance = totalBalance
                    } else {
                        // if there are payments for the violation, get the total amount of payments made to that violation
                        paymentResponse.data.forEach(payment => {
                            if (payment.refundReason !== 'CASH BOND REFUND') { totalPayments += (payment.paymentAmount - payment.convenienceFee) }
                        })
                        // set the remaining balance to the default balance of the violation minus the total amount of payments made
                        row.remainingBalance = totalBalance - totalPayments;

                        row.disableDelete = true;
                    }
                    return row;
                } catch (err) {
                    row.remainingBalance = 0;
                    row.totalFees = 0;
                    row.totalCosts = 0;
                    row.totalFines = 0;
                    return row;
                }
            });

            const vioRow = await Promise.all(fetchBalances);

            // All balance fetch promises have resolved, update the state only once
            const fetchCaseBalance = vioRow.reduce((totalBal, vio) => {
                if (vio.amendedDate == null || vio.amendedDate === "") {
                    totalBal += vio.remainingBalance
                }
                return totalBal
            }, 0);

            const sortedVioRows = vioRow?.sort((a, b) => {
                const isCaseLevelFeeA = a?.caseLevelFee;
                const isCaseLevelFeeB = b?.caseLevelFee;


                if (isCaseLevelFeeA && !isCaseLevelFeeB) return 1
                if (!isCaseLevelFeeA && isCaseLevelFeeB) return -1;

                if (isCaseLevelFeeA && isCaseLevelFeeB) {
                    return a?.chargeType > b?.chargeType ? 1 : a?.chargeType < b?.chargeType ? -1 : 0;
                }

                return 0;
            });

            setBalance(fetchCaseBalance > 0.00 ? fetchCaseBalance : 0.00);

            setViolationRows(sortedVioRows);
            const filteredVioRows = sortedVioRows.filter(row => row.remainingBalance.toFixed(2) > 0.00 && (row.amendedDate === "" || row.amendedDate == null));
            setChargesRows(filteredVioRows);
        } catch (err) {
            console.log('error getting case balance', err);
            setBalance(0);
        }
    };


    const handleTabSelect = (e, selectedTab) => {
        const dataEntryTabs = [0, 1, 2]
        if (changes && !dataEntryTabs.includes(selectedTab)) {
            setFrom([]);
            setModalType("saveChanges");
            setChangesModal(true);
            setValue(selectedTab);
            localStorage.setItem('selectedTab', selectedTab);
        } else {
            setValue(selectedTab);
            localStorage.setItem('selectedTab', selectedTab);
        }
        if (!!violationId) {
            setViolationId(null);
        }
    };

    if (loading) {
        return (
            <Box sx={{ width: "5%", margin: "15vh auto" }}>
                <CircularProgress />
            </Box>
        )
    };

    return (
        <Box sx={{ height: '100%' }}>
            <CitationForm
                caseNumber={caseNumber}
                updateBalanceRows={updateBalanceRows}
                updateCaseBalance={updateCaseBalance}
                value={value}
                handleTabSelect={handleTabSelect}
                changes={changes}
                setChanges={setChanges}
                modalType={modalType}
                setModalType={setModalType}
                handleChangesModal={handleChangesModal}
                changesModal={changesModal}
                setChangesModal={setChangesModal}
                originalState={originalState}
                setOriginalState={setOriginalState}
                from={from}
                setFrom={setFrom}
            />

        </Box>
    )
}

export default HomeView;