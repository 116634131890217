import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Load the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Define the CST timezone string
const CST_TIMEZONE = localStorage.getItem('timeZone') ;

export const formatCST = (date) => {
  return dayjs.utc(date).tz(CST_TIMEZONE);
};

export const formatDateCST = (date) => {
  return formatCST(date).format("MM/DD/YYYY");
};

export const formatTimeCST = (date) => {
  return formatCST(date).format("h:mm A");
};


export const formatDate = (date) => {
    if (typeof date === 'string') {
        // If the input is a date string, split it and create a Date object
        const dateParts = date.split('-');
        date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    }

    const formattedDate = date?.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).replace(/-/g, '/');

    return formattedDate;
};

// using this for disburse report modal date table
export const formattedDate = (value) => {
    const date = new Date(value);

    const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(date);

    return formattedDate;
};

export const formatTime = (value) => {
    const date = new Date(value);

    const formattedTime = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    }).format(date);

    return formattedTime;
};

// Format number as money with 2 decimal places and dollar sign
export const formatMoney = (value) => {
    return `$${parseFloat(value)?.toFixed(2).toLocaleString()}`;
};

// Format currency with commas and dollar sign
export const formatCurrencyWithCommas = (number, minFractionDigits = 0, maxFractionDigits = 2) => {
    if (number) {
        const formattedValue = number.toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: maxFractionDigits,
        });
        return formattedValue;
    } else {
        return '0.00'
    }
};

// Create a function that formats the input
export const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
        const part1 = match[1];
        const part2 = match[2];
        const part3 = match[3];
        let formattedPhoneNumber = '';
        if (part1) {
            formattedPhoneNumber += '(' + part1;
        }
        if (part2) {
            formattedPhoneNumber += ") " + part2;
        }
        if (part3) {
            formattedPhoneNumber += "-" + part3;
        }
        return formattedPhoneNumber;
    }
    return '';
}

// Create a function that removes non-digits
export const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '');
}

export function AddValueAndLabel(fetchedData = [], firstValue, secondValue) {
    const updatedList = fetchedData?.map((item) => ({
        value: item[firstValue]?.toString(),
        label: item[secondValue]?.toString(),
    }));
    return updatedList;
}

export const toTitleCase = (str) => str.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());

export const monthOptions = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
];

// array of all of the state options for the state dropdown input
export const stateOptions = [
    { value: "AL", label: "AL" },
    { value: "AK", label: "AK" },
    { value: "AZ", label: "AZ" },
    { value: "AR", label: "AR" },
    { value: "CA", label: "CA" },
    { value: "CO", label: "CO" },
    { value: "CT", label: "CT" },
    { value: "DE", label: "DE" },
    { value: "FL", label: "FL" },
    { value: "GA", label: "GA" },
    { value: "HI", label: "HI" },
    { value: "ID", label: "ID" },
    { value: "IL", label: "IL" },
    { value: "IN", label: "IN" },
    { value: "IA", label: "IA" },
    { value: "KS", label: "KS" },
    { value: "KY", label: "KY" },
    { value: "LA", label: "LA" },
    { value: "ME", label: "ME" },
    { value: "MD", label: "MD" },
    { value: "MA", label: "MA" },
    { value: "MI", label: "MI" },
    { value: "MN", label: "MN" },
    { value: "MS", label: "MS" },
    { value: "MO", label: "MO" },
    { value: "MT", label: "MT" },
    { value: "NE", label: "NE" },
    { value: "NV", label: "NV" },
    { value: "NH", label: "NH" },
    { value: "NJ", label: "NJ" },
    { value: "NM", label: "NM" },
    { value: "NY", label: "NY" },
    { value: "NC", label: "NC" },
    { value: "ND", label: "ND" },
    { value: "OH", label: "OH" },
    { value: "OK", label: "OK" },
    { value: "OR", label: "OR" },
    { value: "PA", label: "PA" },
    { value: "RI", label: "RI" },
    { value: "SC", label: "SC" },
    { value: "SD", label: "SD" },
    { value: "TN", label: "TN" },
    { value: "TX", label: "TX" },
    { value: "UT", label: "UT" },
    { value: "VT", label: "VT" },
    { value: "VA", label: "VA" },
    { value: "WA", label: "WA" },
    { value: "WV", label: "WV" },
    { value: "WI", label: "WI" },
    { value: "WY", label: "WY" }
];

export const Filters = [
    {
        value: 'NONE',
        label: 'None',
    },
    {
        value: 'BOND',
        label: 'Bond',
    },
    {
        value: 'CRIMINAL',
        label: 'Criminal',
    },
    {
        value: 'TRAFFIC',
        label: 'Traffic',
    }
];

export const GLFilters = [
    {
        value: 'NONE',
        label: 'None',
    },
    {
        value: 'COLLECTIONS',
        label: 'Collections (Deposits)',
    },
    {
        value: 'DISBURSEMENTS',
        label: 'Disbursements'
    }
]

export const PaymentTypeOptions = [
    {
        value: 'none',
        label: 'All',
    },
    {
        value: 'CASH',
        label: 'Cash',
    },
    {
        value: 'CREDIT CARD',
        label: 'Credit Card',
    },
    {
        value: 'CHECK',
        label: 'Check',
    },
    {
        value: 'MONEY ORDER',
        label: 'Money Order',
    }
];

export const TransactionTypeOptions = [
    {
        value: 'CASH BOND',
        label: 'Cash Bond'
    },
    {
        value: 'REFUND',
        label: 'Refund'
    },
    {
        value: 'TIME SERVED',
        label: 'Time Served'
    }
]

export const commaMoneyFormat = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(value);
}