import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios';

import { Box, Button, LinearProgress, TextField, Tooltip, Paper } from '@mui/material';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeModeContext, apiRoute } from '../../../App';
import { formattedDate, formatTime } from '../../../Utils';
import { DistributionCheckContext } from '../../../context/DistributionCheckContext';

const PrintDisbursementChecksModal = ({ handleClose, setCheckDate, checkDate }) => {

    const { checksPrinted, setChecksPrinted } = useContext(DistributionCheckContext)
    const [printing, setPrinting] = useState(false);
    const { mode } = useContext(ThemeModeContext);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [disburseDates, setDisburseDates] = useState([]);
    const [loadingDates, setLoadingDates] = useState(true);
    const formattedDefaultDate = new Date().toISOString().split('T')[0];
    const [checkNumber, setCheckNumber] = useState(0);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textshadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    const handleModalClose = () => {
        setLoadingDates(true);
        handleClose();
    }

    const getDisburseReconDates = () => {
        setLoadingDates(true);
        axios.get(`${apiRoute}/api/DisburseReportViews`)
            .then((res) => {
                const dateRows = res.data.map((row, i) => {
                    return {
                        id: `${row.disburseDate}-${i}`,
                        Date: formattedDate(row.disburseDate),
                        Time: formatTime(row.disburseDate),
                        EnteredBy: row.enteredBy,
                        Value: row.disburseDate
                    }
                })
                setDisburseDates(dateRows);
            })
            .catch((err) => {
                console.error("error getting disburse dates", err);
            })
            .finally(() => setLoadingDates(false))
    };

    const handleDateIconClick = () => {
        setCalendarOpen(!calendarOpen);
    };

    const handlePrintCheckClick = async () => {
        setPrinting(true)
        if (checkNumber) {
            setLoadingDates(true);
            axios.get(`${apiRoute}/api/PrintDisbursementChecks/PrintNew?disburseDate=${disburseDates[0].Value}&checkDate=${checkDate}&checkNumber=${checkNumber}`, { responseType: 'arraybuffer' })
                .then((response) => {
                    const arrayBufferView = new Uint8Array(response.data);
                    const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                    const dataUrl = URL.createObjectURL(blob);
                    setChecksPrinted(true);
                    setPrinting(false);
                    handleClose();
                    window.open(dataUrl, '_blank');
                })
                .catch(async (error) => {
                    setChecksPrinted(false);
                    setLoadingDates(false);
                    setPrinting(false);
                    if (error.response && error.response.data) {
                        // Try to decode the array buffer as JSON
                        const enc = new TextDecoder("utf-8");
                        const responseText = enc.decode(error.response.data);
                        setPrinting(false);
                        try {
                            const json = JSON.parse(responseText);
                            await toast.error(json.message, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                            setChecksPrinted(true);
                        } catch (e) {
                            console.error("Failed to parse error response", e);
                        }
                    } else {
                        await toast.error("Unknown error occurred, please reach out to support", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                })
                .finally(() => setLoadingDates(false))
        } else {
            toast.info('Please select a date and enter a check number', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    useEffect(() => {
        getDisburseReconDates();
    }, []);

    useEffect(() => {
        setCheckDate(formattedDefaultDate)
    }, []);

    return (
        <Paper elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                <h1 style={{ fontWeight: 'bold' }}>Disbursement Dates</h1>
            </Paper>
            <Box p={1}>
                <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: '2vh', mb: '1vh' }} >
                    <TextField
                        id="check-date"
                        label="Check Date"
                        type="date"
                        value={checkDate}
                        onChange={e => setCheckDate(e.target.value)}
                        onClick={() => handleDateIconClick()}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        sx={{ ...textFieldStyle }}
                        disabled={checksPrinted}
                        helperText={checksPrinted ? "Checks have already been printed" : null}
                    />
                    <TextField
                        id="check-number"
                        label="Check Number"
                        value={checkNumber}
                        onChange={e => setCheckNumber(e.target.value)}
                        variant="outlined"
                        helperText={checksPrinted ? "Checks have already been printed" : "Please enter the first check number"}
                        InputLabelProps={{ shrink: true }}
                        sx={{ ...textFieldStyle }}
                        disabled={checksPrinted}
                    />
                </Box>
                <hr />
                <Box
                    sx={
                        {
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: '1rem',
                            mt: '1vh'
                        }
                    }
                >
                    <Tooltip title={checksPrinted && "Checks Have Already Been Printed"}>
                        <span>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "steelblue", color: "white" }}
                                onClick={handlePrintCheckClick}
                                disabled={checksPrinted}
                            >
                                Print Checks
                            </Button>
                        </span>
                    </Tooltip>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "steelblue", color: "white" }}
                        onClick={handleModalClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
            {printing && (
                <div style={{ marginTop: '1vh' }}>
                    <LinearProgress />
                </div>
            )}
        </Paper>
    )
}

export default PrintDisbursementChecksModal;