import React, { createContext, useCallback, useState } from 'react';
import axios from 'axios';

import { apiRoute } from '../App.js';

const CaseContext = createContext({
    citationView: {},
    setCitationView: () => { },
    caseView: {},
    setCaseView: () => { },
    violationId: [],
    setViolationId: () => { },
    violationRows: [],
    setViolationRows: () => { },
    feeFineRows: [],
    setFeeFineRows: () => { },
    caseHistoryRows: [],
    setCaseHistoryRows: () => { },
    balanceRows: [],
    setBalanceRows: () => { },
    balances: {},
    setBalances: () => { },
    paymentRows: [],
    setPaymentRows: () => { },
    receiptNumber: "",
    setReceiptNumber: () => { },
    loadingFF: false,
    setLoadingFF: () => { },
    setLoadingStatutes: () => { },
    assessFines: 0,
    setAssessFines: () => { },
    assessFees: 0,
    setAssessFees: () => { },
    assessTotal: 0,
    setAssessTotal: () => { },
    suspendFines: 0,
    setSuspendFines: () => { },
    suspendFees: 0,
    setSuspendFees: () => { },
    suspendTotal: 0,
    setSuspendTotal: () => { },
    netFines: 0,
    setNetFines: () => { },
    netFees: 0,
    setNetFees: () => { },
    netTotal: 0,
    setNetTotal: () => { },
    balance: 0,
    setBalance: () => { },
    loading: true,
    setLoading: () => { },
    files: [],
    setFiles: () => { },
    violationDate: '',
    setViolationDate: () => { },
    daysInLieu: 0,
    setDaysInLieu: () => { },
    timeServed: 0,
    setTimeServed: () => { },
    suspendCaseBalance: () => { },
    chargesRows: [],
    setChargesRows: () => { },
    autoBondNum: Boolean,
    setAutoBondNum: () => { },
    cashBondRows: [],
    setCashBondRows: () => { },
    bondRows: [],
    setBondRows: () => { },
    assessCosts: 0,
    setAssessCosts: () => { },
    suspendCosts: 0,
    setSuspendCosts: () => { },
    getVioTransactionTbl: () => { },
    vioTransactionTbl: {},
    setVioTransactionTbl: () => { },
    resetCaseContext: () => { },
    resetCaseContextPromise: () => { },
})

const CaseProvider = ({ children }) => {
    const [citationView, setCitationView] = useState({});
    const [caseView, setCaseView] = useState({});
    const [violationId, setViolationId] = useState(null);
    const [violationRows, setViolationRows] = useState([]);
    const [caseHistoryRows, setCaseHistoryRows] = useState([]);
    const [feeFineRows, setFeeFineRows] = useState([]);
    const [balanceRows, setBalanceRows] = useState([]);
    const [balances, setBalances] = useState({});
    const [paymentRows, setPaymentRows] = useState([]);
    const [receiptNumber, setReceiptNumber] = useState("");
    const [statuteRows, setStatuteRows] = useState([]);
    const [assessFines, setAssessFines] = useState(0);
    const [assessFees, setAssessFees] = useState(0);
    const [assessCosts, setAssessCosts] = useState(0);
    const [assessTotal, setAssessTotal] = useState(0);
    const [suspendFines, setSuspendFines] = useState(0);
    const [suspendFees, setSuspendFees] = useState(0);
    const [suspendCosts, setSuspendCosts] = useState(0);
    const [suspendTotal, setSuspendTotal] = useState(0);
    const [netFines, setNetFines] = useState(0);
    const [netFees, setNetFees] = useState(0);
    const [netCosts, setNetCosts] = useState(0);
    const [netTotal, setNetTotal] = useState(0);
    const [balance, setBalance] = useState(0);
    const [files, setFiles] = useState([]);
    const [violationDate, setViolationDate] = useState('');
    const [daysInLieu, setDaysInLieu] = useState(0);
    const [timeServed, setTimeServed] = useState(0);
    const [chargesRows, setChargesRows] = useState([]);
    const [bondRows, setBondRows] = useState([]);
    const [cashBondRows, setCashBondRows] = useState([]);
    const [vioTransactionTbl, setVioTransactionTbl] = useState({});

    //! --------------- LOADING STATES ---------------
    const [loading, setLoading] = useState(true);
    const [loadingFF, setLoadingFF] = useState(false);

    const resetCaseContext = async () => {
        setCitationView({});
        setCaseView({});
        setViolationId(null);
        setViolationRows([]);
        setCaseHistoryRows([]);
        setFeeFineRows([]);
        setBalanceRows([]);
        setBalances({});
        setPaymentRows([]);
        setReceiptNumber('');
        setStatuteRows([]);
        setAssessFines(0);
        setAssessFees(0);
        setAssessCosts(0);
        setAssessTotal(0);
        setSuspendFines(0);
        setSuspendFees(0);
        setSuspendCosts(0);
        setSuspendTotal(0);
        setNetFines(0);
        setNetFees(0);
        setNetCosts(0);
        setNetTotal(0);
        setBalance(0);
        setFiles([]);
        setViolationDate('');
        setDaysInLieu(0);
        setTimeServed(0);
        setChargesRows([]);
        setBondRows([]);
        setCashBondRows([]);
        setVioTransactionTbl({});
        setLoading(true);
        setLoadingFF(false);
    };

    const resetCaseContextPromise = async () => {
        return new Promise((resolve) => {
            resetCaseContext();
            resolve();
        })
    }

    const hasToken = localStorage.getItem('token') != null || localStorage.getItem('token') !== undefined;

    const calculateSums = (data) => {
        const initialSums = {
            assessFeeSum: 0,
            assessFineSum: 0,
            assessCostSum: 0,
            suspendFeeSum: 0,
            suspendFineSum: 0,
            suspendCostSum: 0,
        };

        const sums = data.reduce((acc, item) => {
            if (item.applyTo === "FEE") {
                if (item.isCourtCost === 'Y') {
                    acc.assessCostSum += item.assessAmount
                    acc.suspendCostSum += item.suspendAmount
                } else {
                    acc.assessFeeSum += item.assessAmount;
                    acc.suspendFeeSum += item.suspendAmount;
                }
            } else if (item.applyTo === "FINE") {
                acc.assessFineSum += item.assessAmount;
                acc.suspendFineSum += item.suspendAmount;
            }
            return acc;
        }, initialSums);

        sums.netFeeSum = sums.assessFeeSum - sums.suspendFeeSum;
        sums.netFineSum = sums.assessFineSum - sums.suspendFineSum;
        sums.netCostSum = sums.assessCostSum - sums.suspendCostSum

        sums.assessTotalSum = sums.assessFeeSum + sums.assessFineSum + sums.assessCostSum;
        sums.suspendTotalSum = sums.suspendFeeSum + sums.suspendFineSum + sums.suspendCostSum;
        sums.netTotalSum = sums.netFeeSum + sums.netFineSum + sums.netCostSum;

        return sums;
    };

    //* -------------------- FUNCTION TO CALL ALL NEEDED APIS FOR PAGE LOAD ---------------------
    const fetchData = useCallback(
        async (caseNumber) => {
            setLoading(true);
            try {
                // variables used throughout the function
                let citationId;
                let violationData;
                let citation;

                try {
                    //* -------------------- GET CASE DATA BY CASE ID ---------------------
                    const { data } = await axios.get(`${apiRoute}/api/CaseTbls/${caseNumber}`);
                    citationId = data.fkCitationIdName;
                    setViolationDate(data.violationDate);
                    const formattedViolationDate = new Date(data.violationDate).toISOString().substring(0, 10);
                    const formattedDateFiled = data?.dateFiled ? new Date(data?.dateFiled).toISOString().substring(0, 10) : '';
                    const newCaseData = {
                        ...data,
                        violationDate: formattedViolationDate,
                        dateFiled: formattedDateFiled
                    };
                    setCaseView(newCaseData);
                } catch (err) {
                }

                try {
                    //* -------------------- GET CITATION DATA BY CITATION ID ---------------------
                    const { data } = await axios.get(`${apiRoute}/api/CitationTbls/${citationId}`);
                    citation = data;
                    function safeDateToISO(dateString) {
                        let date = new Date(dateString);
                        return isNaN(date.getTime()) ? null : date.toISOString().substring(0, 10);
                    }
                    const newCitationData = {
                        ...citation,
                        citDatetime: citation.citDatetime ? safeDateToISO(citation.citDatetime) : '',
                        courtDatetime: citation.courtDatetime ? safeDateToISO(citation.courtDatetime) : '',
                        dateOfBirth: citation.dateOfBirth ? safeDateToISO(citation.dateOfBirth) : '',
                        arrestDatetime: citation.arrestDatetime ? safeDateToISO(citation.arrestDatetime) : '',
                        dateEnter: citation.dateEnter ? safeDateToISO(citation.dateEnter) : '',
                        lastUpdate: citation.lastUpdate ? safeDateToISO(citation.lastUpdate) : '',
                        arraignmentDate: citation.arraignmentDate ? safeDateToISO(citation.arraignmentDate) : '',
                        trialDate: citation.trialDate ? safeDateToISO(citation.trialDate) : '',
                        warrantIssuedDate: citation.warrantIssuedDate ? safeDateToISO(citation.warrantIssuedDate) : '',
                        schoolZone: citation?.schoolZone == null ? 'N' : citation.schoolZone,
                        constructionZone: citation?.constructionZone == null ? 'N' : citation?.constructionZone,
                    }

                    citation = newCitationData;

                    setCitationView(newCitationData);
                } catch (err) {
                }


                try {
                    //* -------------------- GET VIOLATION VIEWS DATA BY CASE ID ---------------------
                    const { data } = await axios.get(`${apiRoute}/api/ViolationViews/ByCaseId/${caseNumber}`);
                    violationData = data;
                    const fetchBalances = data.map(async (item) => {
                        const row = {
                            ...item,
                            id: item.pkViolationId,
                            balance: 0,
                            disableDelete: false,
                            dueDate: citation.courtDatetime,
                        };
                        // fetch balance for each row
                        try {
                            const { data } = await axios.get(`${apiRoute}/api/FeeFineTbls/ffTotals/${row.id}/${citationId}`);
                            let totalBalance = data.fineTotal + data.costTotal + data.feeTotal;

                            // Add the calculated balance to the row
                            row.balance = totalBalance;
                            row.totalFees = data.feeTotal;
                            row.totalFines = data.fineTotal;
                            row.totalCosts = data.costTotal;

                            // api call to fetch all payments for each violation attached to the case
                            const paymentResponse = await axios.get(`${apiRoute}/api/FfPaymentTbls/violationid/${row.id}`);
                            let totalPayments = 0;
                            let totalConvenienceFee = 0;
                            // api call to see if there are any cash bonds attached to the row (violation), returns a boolean
                            const bondResponse = await axios.get(`${apiRoute}/api/bond/violation/${row.id}`);

                            row.disableDelete = bondResponse.data;

                            // check to see if there are any payments attached to the violation
                            if (paymentResponse.data.length < 1) {

                                // if there are no payments, set the remaining balance to be the total balance of the violation
                                row.remainingBalance = totalBalance
                            } else {

                                // if there are payments for the violation, get the total amount of payments made to that violation
                                // if there are payments for the violation, get the total amount of payments made to that violation
                                paymentResponse.data.forEach(payment => {
                                    if (payment.refundReason !== 'CASH BOND REFUND') {
                                        totalPayments += (payment.paymentAmount);
                                        totalConvenienceFee += payment.convenienceFee
                                    }
                                })

                                // set the remaining balance to the default balance of the violation minus the total amount of payments made
                                row.remainingBalance = totalBalance - totalPayments;

                                // hard set the disableDelete for the current violation to true if there is a payment that was made on this vio
                                row.disableDelete = true;
                            }
                            return row;
                        } catch (err) {
                            row.remainingBalance = 0;
                            row.totalCosts = 0;
                            row.totalFees = 0;
                            row.totalFines = 0;
                            return row;
                        }
                    });

                    const vioRows = await Promise.all(fetchBalances);

                    // All balance fetch promises have resolved, update the state only once
                    const fetchCaseBalance = vioRows.reduce((totalBal, vio) => {
                        if (vio.amendedDate == null || vio.amendedDate === "") {
                            totalBal += vio.remainingBalance
                        }
                        return totalBal
                    }, 0);

                    const sortedVioRows = vioRows?.sort((a, b) => {
                        const isCaseLevelFeeA = a?.caseLevelFee;
                        const isCaseLevelFeeB = b?.caseLevelFee;


                        if (isCaseLevelFeeA && !isCaseLevelFeeB) return 1
                        if (!isCaseLevelFeeA && isCaseLevelFeeB) return -1;

                        if (isCaseLevelFeeA && isCaseLevelFeeB) {
                            return a?.chargeType > b?.chargeType ? 1 : a?.chargeType < b?.chargeType ? -1 : 0;
                        }

                        return 0;
                    });

                    setBalance(fetchCaseBalance < '0.00' ? '0.00' : fetchCaseBalance);

                    setViolationRows(sortedVioRows);
                    const filteredVioRows = sortedVioRows.filter(row => row.remainingBalance.toFixed(2) > 0.00 && (row.amendedDate === "" || row.amendedDate == null));
                    setChargesRows(filteredVioRows);
                } catch (err) {
                }

                if (violationData?.length > 0) {
                    //* -------------------- GET FEE FINE DATA BY CASE ID ---------------------
                    try {
                        const { data } = await axios.get(`${apiRoute}/api/FeeFineTbls/FkCaseId/${caseNumber}`);
                        if (data.length === 0) {
                            setBalanceRows([]);
                            setAssessFines(0);
                            setAssessCosts(0);
                            setAssessFees(0);
                            setAssessTotal(0);
                            setSuspendFines(0);
                            setSuspendFees(0);
                            setSuspendCosts(0)
                            setSuspendTotal(0);
                            setNetFines(0);
                            setNetFees(0);
                            setNetTotal(0);
                        } else {
                            setBalanceRows(data);
                            const {
                                assessFeeSum,
                                assessFineSum,
                                assessCostSum,
                                assessTotalSum,
                                suspendFeeSum,
                                suspendFineSum,
                                suspendCostSum,
                                suspendTotalSum,
                                netFeeSum,
                                netFineSum,
                                netCostSum,
                                netTotalSum,
                            } = calculateSums(data);

                            setAssessFines(assessFineSum);
                            setAssessCosts(assessCostSum)
                            setAssessFees(assessFeeSum);
                            setAssessTotal(assessTotalSum);
                            setSuspendFines(suspendFineSum);
                            setSuspendFees(suspendFeeSum);
                            setSuspendCosts(suspendCostSum);
                            setSuspendTotal(suspendTotalSum);
                            setNetFines(netFineSum);
                            setNetFees(netFeeSum);
                            setNetCosts(netCostSum);
                            setNetTotal(netTotalSum);
                        }
                    } catch (err) {
                    }
                }

                try {
                    //* -------------------- GET PAYMENT DATA BY CASE ID ---------------------
                    const { data } = await axios.get(`${apiRoute}/api/ffpaymenttbls/caseid/${caseNumber}`);
                    const dateFormat = data.map(item => {
                        return {
                            ...item,
                            id: item.pkFfPaymentId,
                            paymentDate: new Date(item.paymentDate).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            }).replace(/\//g, '-'),
                            dateEnter: new Date(item.dateEnter).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            }).replace(/\//g, '-'),
                            receiptNumber: item.receiptNum
                        };
                    });
                    setCaseHistoryRows(dateFormat);
                } catch (err) {
                }

                //try {
                //    //* -------------------- FETCH ALL CASH BONDS ATTACHED TO CASE/CITATION ---------------------
                //    const { data } = await axios.get(`${apiRoute}/api/bond/citation/${citationId}`);
                //    const rows = data.map((row) => {
                //        const newRow = {
                //            ...row,
                //            incidentDate: row.incidentDate ? new Date(row.incidentDate).toISOString().substring(0, 10) : null,
                //            reimbursementDate: row.reimbursementDate ? new Date(row.reimbursementDate).toISOString().substring(0, 10) : null,
                //            bondDate: row.bondDate ? new Date(row.bondDate).toISOString().substring(0, 10) : null,
                //            appliedToCaseDate: row.appliedToCaseDate ? new Date(row.appliedToCaseDate).toISOString().substring(0, 10) : null,
                //        }
                //        return newRow;
                //    })
                //    setCashBondRows(rows);
                //} catch (err) {
                //}

                try {
                    //* -------------------- FETCH ALL BONDS ATTACHED TO CASE/CITATION ---------------------
                    const { data } = await axios.get(`${apiRoute}/api/bond/case/${caseNumber}`);
                    const sourceIds = [];
                    data.forEach(d => d.bond.sourceId == null ? sourceIds.push(d) : null);
                    const rows = data.map((row) => {
                        const newRow = {
                            ...row.bond,
                            incidentDate: row.bond.incidentDate ? new Date(row.bond.incidentDate).toISOString().substring(0, 10) : null,
                            bondDate: row.bond.bondDate ? new Date(row.bond.bondDate).toISOString().substring(0, 10) : null,
                            originalPostedAmount: data && data[0].bond.bondAmount ? data[0].bond.bondAmount : null,
                            remainingBondAmount: row.bond.bondType === 'CASH BOND' ? row.bond.cashBondTransactions.reduce((remaining, transaction) => {
                                if (transaction.isActive && !transaction.isVoid) {
                                    return remaining - transaction.transactionAmount;
                                }
                                return remaining
                            }, row.bond.bondAmount) : sourceIds.filter(r => r.bond.id === row.bond?.sourceId)[0]?.bond.cashBondTransactions?.reduce((remaining, transaction) => {
                                if (transaction.isActive && !transaction.isVoid) {
                                    return remaining - transaction.transactionAmount;
                                }
                                return remaining
                            }, sourceIds.filter(r => r.bond.id === row.bond.sourceId)[0].bond.bondAmount),
                            statuteIds: row.statuteIds,
                            voidDate: sourceIds?.filter(r => r.bond.id === row.bond.sourceId)[0]?.bond.cashBondTransactions?.filter(c => c.isVoid === true && c.transactionType !== 'APPLY' && -c.transactionAmount === row.bond.bondAmount)[0]?.voidDate ?? null
                        }
                        return newRow;
                    });
                    setBondRows(rows)
                } catch (err) {

                }


            } catch (err) {
                console.log("❌ FETCH DATA API CALL ERR", err)
            } finally {
                setLoading(false);
            }
        },
        [],
    );

    const getVioTransactionTbl = async (id) => {
        axios.get(`${apiRoute}/api/TransactionViolationsTbls/violationId/${id}`)
            .then(({ data }) => {
                setVioTransactionTbl(data);
            })
            .catch(err => {
                console.log("Error getting VioTransactionTbl")
            })
    }


    const contextValue = {
        citationView: citationView,
        setCitationView,
        caseView: caseView,
        setCaseView,
        violationId: violationId,
        setViolationId,
        violationRows: violationRows,
        setViolationRows,
        feeFineRows: feeFineRows,
        setFeeFineRows,
        caseHistoryRows: caseHistoryRows,
        setCaseHistoryRows,
        balanceRows: balanceRows,
        setBalanceRows,
        balances: balances,
        setBalances,
        paymentRows: paymentRows,
        setPaymentRows,
        receiptNumber: receiptNumber,
        setReceiptNumber,
        loadingFF: loadingFF,
        setLoadingFF,
        statuteRows: statuteRows,
        setStatuteRows,
        assessFines: assessFines,
        setAssessFines,
        assessFees: assessFees,
        setAssessFees,
        assessCosts: assessCosts,
        setAssessCosts,
        assessTotal: assessTotal,
        setAssessTotal,
        suspendFines: suspendFines,
        setSuspendFines,
        suspendFees: suspendFees,
        setSuspendFees,
        suspendCosts: suspendCosts,
        setSuspendCosts,
        suspendTotal: suspendTotal,
        setSuspendTotal,
        netFines: netFines,
        setNetFines,
        netFees: netFees,
        setNetFees,
        netCosts: netCosts,
        setNetCosts,
        netTotal: netTotal,
        setNetTotal,
        balance: balance,
        setBalance,
        calculateSums,
        loading: loading,
        setLoading,
        fetchData,
        files: files,
        setFiles,
        violationDate: violationDate,
        setViolationDate,
        daysInLieu: daysInLieu,
        setDaysInLieu,
        timeServed: timeServed,
        setTimeServed,
        chargesRows: chargesRows,
        setChargesRows,
        bondRows: bondRows,
        setBondRows,
        cashBondRows: cashBondRows,
        setCashBondRows,
        getVioTransactionTbl,
        vioTransactionTbl: vioTransactionTbl,
        setVioTransactionTbl,
        resetCaseContextPromise
    }

    return (
        <CaseContext.Provider value={contextValue}>
            {children}
        </CaseContext.Provider>
    )
}

export default CaseProvider;
export { CaseContext };