import React, { useContext } from 'react';

import FeeRuleForm from '../components/FeeRuleForm';
import CourtForm from '../components/CourtForm';
import DivisionForm from '../components/DivisionForm';
import DisbursementRuleForm from '../components/DisbursementRuleForm';
import PayeeForm from '../components/PayeeForm';
import SpeedingAmountForm from '../components/SpeedingAmountForm';
import SyscodeForm from '../components/SyscodeForm';
import UserForm from '../components/UserForm';
import AgencyForm from '../components/AgencyForm';
import OfficerForm from '../components/OfficerForm';
import BankForm from '../components/BankForm';
import GlAccountsForm from '../components/GlAccountsForm';

import { ThemeModeContext } from '../../../../../App';
import StatuteFineForm from '../components/StatuteFineForm';
import EntityForm from '../components/EntityForm';

const UtilitiesModals = (props) => {
    const { modalType, modalName, handleClose, data, loading, setLoading, rows, setRows, getRows } = props;

    const { mode } = useContext(ThemeModeContext);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === 'dark' ? '#4C4E52' : 'white',
            color: mode === 'dark' ? 'white' : 'black',
        },
        '& .MuiInputLabel-root': {
            color: mode === 'dark' ? 'white' : 'black',
            textshadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    return (
        <>
            {
                modalName === 'Agencies' && (
                    <>
                        <AgencyForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Statutes/Charge Codes' && (
                    <>
                        <StatuteFineForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Courts' && (
                    <>
                        <CourtForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Divisions' && (
                    <>
                        <DivisionForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Disbursement Rules' && (
                    <>
                        <DisbursementRuleForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Organization Info' && (
                    <>
                        <EntityForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Fee Rules' && (
                    <>
                        <FeeRuleForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Officers' && (
                    <>
                        <OfficerForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Payees' && (
                    <>
                        <PayeeForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Speeding Amounts' && (
                    <>
                        <SpeedingAmountForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'System Codes' && (
                    <>
                        <SyscodeForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Users' && (
                    <>
                        <UserForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Banks' && (
                    <>
                        <BankForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                        />
                    </>
                )
            }
            {
                modalName === 'Gl Accounts' && (
                    <>
                        <GlAccountsForm
                            handleClose={handleClose}
                            modalType={modalType}
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            ts={textFieldStyle}
                            rows={rows}
                            setRows={setRows}
                            getRows={getRows}
                            />
                    </>
                )
            }
        </>
    )
}

export default UtilitiesModals;