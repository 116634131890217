import React from 'react';
import axios from 'axios'
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
import { PiGavelBold } from 'react-icons/pi';
import { apiRoute } from '../../../../App';

const ViolationActions = (props) => {
    const {
        anchorEl,
        params,
        handleCloseDropdown,
        handleOpen,
        disbursedFees,
        addFeesCheck,
        setViolationData,
        state,
        addSuspendedCheck
    } = props;

    return (
        <Menu
            id='violation-menu'
            anchorEl={anchorEl[params.row.id]}
            open={Boolean(anchorEl[params.row.id])}
            onClose={() => handleCloseDropdown(params.row.id)}
            MenuListProps={{
                'aria-labelledby': 'violation-actions',
            }}
        >
            <MenuItem onClick={handleCloseDropdown}>
                <Tooltip
                    title={<h6>Can't delete a violation that has a payment or cash bond applied to it</h6>}
                    disableHoverListener={!params.row.disableDelete}
                    placement="left"
                >
                    <span style={{ width: '100%' }}>
                        <Button
                            fullWidth
                            onClick={(e) => {
                                handleOpen('delete', '40%');
                                handleCloseDropdown(params.row.id);
                            }}
                            variant="contained"
                            sx={{ backgroundColor: "steelblue", color: 'white' }}
                            disabled={params.row.disableDelete | !state.isActive | state.isDeleted}
                        >
                            Delete Violation
                        </Button>
                    </span>
                </Tooltip>
            </MenuItem>
            <MenuItem onClick={handleCloseDropdown}>
                <Button
                    fullWidth
                    onClick={() => {
                        handleOpen('addFeesByType', '50%');
                        handleCloseDropdown(params.row.id);
                    }}
                    variant="contained"
                    disabled={params.row.currentStatute === 'DISMISSED' | !state.isActive | state.isDeleted}
                    sx={{ backgroundColor: "steelblue", color: 'white' }}
                >
                    Add Fees By Charge Type
                </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseDropdown}>
                <Button
                    fullWidth
                    onClick={async () => {
                        handleOpen('sentencing', '75%');
                        handleCloseDropdown(params.row.id);
                        axios.get(`${apiRoute}/api/ViolationTbls/${params.row.pkViolationId}`)
                            .then(res => {
                                setViolationData(res.data);
                                handleOpen('sentencing', '75%')
                            })
                            .catch(err => console.log(err))
                    }
                    }
                    variant="contained"
                    sx={{ backgroundColor: "steelblue", color: 'white' }}
                >
                    Sentencing Details&nbsp; <PiGavelBold style={{ fontSize: '18px' }} />
                </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseDropdown}>
                <Button
                    fullWidth
                    onClick={() => {
                        handleOpen('violationInfo', '50%', true);
                        handleCloseDropdown(params.row.id);
                    }}
                    variant="contained"
                    sx={{ backgroundColor: "steelblue", color: 'white' }}
                > Violation Info&nbsp; <Info />
                </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseDropdown}>
                <Button
                    fullWidth
                    onClick={() => {
                        handleOpen('Comments', '40%');
                        handleCloseDropdown(params.row.id);
                    }}
                    variant="contained"
                    sx={{ backgroundColor: "steelblue", color: 'white' }}
                >
                    Comments
                </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseDropdown}>
                <Button
                    fullWidth
                    sx={{ backgroundColor: 'gold', color: 'black' }}
                    variant="contained"
                    onClick={() => {
                        handleOpen('suspendAll', '45%');
                        handleCloseDropdown(params.row.id);
                    }}
                    disabled={addSuspendedCheck() ? true : !addFeesCheck() ? true : !state.isActive ? true : state.isDeleted ? true : disbursedFees}
                >
                    <strong>
                        Suspend All
                    </strong>
                </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseDropdown}>
                <Button
                    fullWidth
                    sx={{ backgroundColor: 'gold', color: 'black' }}
                    variant="contained"
                    onClick={(e) => {
                        handleOpen('deleteFees', '45%');
                        handleCloseDropdown(params.row.id);
                    }}
                    disabled={addSuspendedCheck() | !addFeesCheck() | !state.isActive | state.isDeleted}
                >
                    <strong>
                        Clear All
                    </strong>
                </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseDropdown}>
                <Button
                    fullWidth
                    sx={{ backgroundColor: '#50C878', color: 'black' }}
                    variant="contained"
                    onClick={() => {
                        handleOpen('revertSuspended', '45%');
                        handleCloseDropdown(params.row.id);
                    }}
                    disabled={addSuspendedCheck() ? false : addFeesCheck() ? true : !state.isActive ? true : state.isDeleted ? true : disbursedFees}
                >
                    <strong>
                        Revert Suspended
                    </strong>
                </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseDropdown}>
                <Button
                    fullWidth
                    sx={{ backgroundColor: '#50C878', color: 'black' }}
                    variant="contained"
                    onClick={() => {
                        handleOpen('revertCleared', '45%');
                        handleCloseDropdown(params.row.id);
                    }}
                    disabled={addSuspendedCheck() ? true : addFeesCheck() ? true : !state.isActive ? true : state.isDeleted ? true : disbursedFees}
                >
                    <strong>
                        Revert Cleared
                    </strong>
                </Button>
            </MenuItem>
        </Menu >
    )
}

export default ViolationActions;