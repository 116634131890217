import React, { useContext, useEffect, useReducer, useState, useRef, } from 'react';
import axios from 'axios';

import { Box, Button, FormControlLabel, Grid, InputAdornment, MenuItem, Paper, Radio, TextField, Tooltip, Typography, RadioGroup } from '@mui/material';
import { MdEditOff, MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from 'react-toastify';

import { CaseContext } from '../../../../context/CaseContext';
import { BatchContext } from '../../../../context/BatchMgmtContext';
import { SystemContext } from '../../../../context/SystemContext';


import { ThemeModeContext, apiRoute } from '../../../../App.js';
import { cleanPhoneNumber, commaMoneyFormat, formatMoney, formatPhoneNumber, stateOptions } from '../../../../Utils';

const BondsForm = (props) => {
    const {
        bondNumber,
        handleClose,
        modalType,
        selectedBond,
        setBondRows,
        setLoading,
        loading,
        ts,
        selectedStatutes
    } = props;

    const { citationView, violationRows, caseView, setCashBondRows } = useContext(CaseContext);

    const { mode } = useContext(ThemeModeContext);

    const { paymentBatchNumber, setPaymentBatchNumber, openBatches, setOpenBatches } = useContext(BatchContext);

    const { autoBondNum } = useContext(SystemContext);

    const amountRef = useRef(null);

    const initialBondState = {
        bondNumber: modalType === 'edit' ? selectedBond.bondNumber || '' : autoBondNum ? bondNumber : '',
        incidentDate: modalType === 'edit' ? selectedBond.incidentDate || null : null,
        incidentLocation: modalType === 'edit' ? selectedBond.incidentLocation || '' : '',
        postedLast: modalType === 'edit' ? selectedBond.postedLast || '' : '',
        postedFirst: modalType === 'edit' ? selectedBond.postedFirst || '' : '',
        postedPhone1: modalType === 'edit' ? selectedBond.postedPhone1 || '' : '',
        postedAddress1: modalType === 'edit' ? selectedBond.postedAddress1 || '' : '',
        postedAddress2: modalType === 'edit' ? selectedBond.postedAddress2 || '' : '',
        postedCity: modalType === 'edit' ? selectedBond.postedCity || '' : '',
        postedState: modalType === 'edit' ? selectedBond.postedState || '' : '',
        postedZip: modalType === 'edit' ? selectedBond.postedZip || '' : '',
        bondAmount: modalType === 'edit' ? selectedBond.bondAmount || 0 : 0,
        reimbursementDate: modalType === 'edit' ? selectedBond.reimbursementDate ? selectedBond.reimbursementDate : null : null,
        checkNumber: modalType === 'edit' ? selectedBond.checkNumber || '' : '',
        bondDate: modalType === 'edit' ? selectedBond.bondDate ? selectedBond?.bondDate?.split('T')[0] : null : null,
        statute: modalType === 'edit' ? violationRows.filter(v => v.pkViolationId === selectedBond.violationIdFk)[0]?.currentStatute : '',
        batchNumber: modalType === 'edit' ? openBatches[0].batchNumber : null,
        bondType: modalType === 'edit' ? selectedBond.bondType : 'SURETY BOND'
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialBondState);

    const [displayPhone, setDisplayPhone] = useState(formatPhoneNumber(state.postedPhone1));
    const [displayBondAmount, setDisplayBondAmount] = useState(formatMoney(parseFloat(state?.bondAmount)));
    const [dataEntry, setDataEntry] = useState(true);
    const [paymentOptions, setPaymentOptions] = useState(['CASH', 'CASHIERS CHECK', 'MONEY ORDER']);
    const [paymentStatute, setPaymentStatute] = useState('');
    const [errors, setErrors] = useState({});
    const [bondTypeOptions, setBondTypeOptions] = useState(['CASH BOND', 'SURETY BOND', 'PROPERTY BOND', 'OR BOND']);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const fieldValidations = {
        bondNumber: {
            errorRequired: 'Bond Number is Required',
            validation: (value) => !value,
        },
        postedLast: {
            errorRequired: 'Last Name is required',
            validation: (value) => !value,
        },
        postedFirst: {
            errorRequired: 'First Name is required',
            validation: (value) => !value,
        },
        bondAmount: {
            errorRequired: 'Bond Amount is required',
            validation: (value) => !value,
        },
        bondDate: {
            errorRequired: 'Bond Date is required',
            validation: (value) => !value,
        },
        postedZip: {
            errorRequired: 'Zip Code is required',
            errorFormat: 'Invalid ZIP Code',
            validation: (value) => !value || /^\d{5}(?:-\d{4})?$/.test(value),
        }
    }

    const handleBlur = (field, value) => {
        const fieldInfo = fieldValidations[field];
        let error = null;

        if (fieldInfo) {
            if (!value) {
                error = fieldInfo.errorRequired;
            } else if (fieldInfo.errorFormat && !fieldInfo.validation(value)) {
                error = fieldInfo.errorFormat;
            }
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const submitCashBond = async (e) => {
        e.preventDefault();

        setLoading(true);
        bondPayments.forEach(b => b.paymentRef = paymentStatute);

        const findCheck = bondPayments.find(b => b.paymentType.toUpperCase() === "MONEY ORDER" || b.paymentType.toUpperCase() === "CASHIERS CHECK")
        const findCheckIndex = bondPayments.findIndex(b => b.paymentType.toUpperCase() === "MONEY ORDER" || b.paymentType.toUpperCase() === "CASHIERS CHECK")

        if (findCheck && findCheck.checkNumber === "") {
            setErrors({ ...errors, [findCheckIndex]: 'Check Number Required' });
            return;
        }

        const bondData = {
            Bond: {
                CaseIdFk: caseView.pkCaseId,
                incidentDate: state.incidentDate,
                incidentLocation: state.incidentLocation,
                postedLast: state.postedLast,
                postedFirst: state.postedFirst,
                postedPhone1: state.postedPhone1,
                postedAddress1: state.postedAddress1,
                postedAddress2: state.postedAddress2,
                postedCity: state.postedCity,
                postedState: state.postedState,
                postedZip: state.postedZip,
                bondAmount: parseFloat(state.bondAmount),
                bondNumber: state.bondNumber,
                reimbursementDate: state.reimbursementDate,
                checkNumber: state.checkNumber,
                bondDate: state.bondDate,
                currentStatute: state.statute,
                batchNumber: paymentBatchNumber,
                bondType: state.bondType
            },
            Payments: modalType === 'form' ? bondPayments : null,
            Statutes: selectedStatutes.map(statute => statute.id)
        };

        try {
            const res = modalType === 'form'
                ? await axios.post(`${apiRoute}/api/bond`, bondData)
                : await axios.put(`${apiRoute}/api/bond/${selectedBond.id}/${caseView.pkCaseId}`, { ...bondData, id: selectedBond.id })

            const newRows = res.data.map((row) => {
                const newRow = {
                    ...row.bond,
                    incidentDate: row.bond.incidentDate ? new Date(row.bond.incidentDate).toISOString().substring(0, 10) : null,
                    reimbursementDate: row.bond.reimbursementDate ? new Date(row.bond.reimbursementDate).toISOString().substring(0, 10) : null,
                    bondDate: row.bond.bondDate ? new Date(row.bond.bondDate).toISOString() : null,
                    appliedToCaseDate: row.bond.appliedToCaseDate ? new Date(row.bond.appliedToCaseDate).toISOString().substring(0, 10) : null,
                    remainingBondAmount: modalType === 'form' ? row.bond.bondAmount : row.bond.cashBondTransactions.reduce((remaining, transaction) => {
                        if (transaction.isActive) {
                            return remaining - transaction.transactionAmount;
                        }
                        return remaining
                    }, row.bond.bondAmount),
                    statuteIds: row.statuteIds
                }
                return newRow;
            })
            setBondRows(newRows);
            toast.success(modalType === 'form' ? `Bond ${bondData.Bond.bondNumber} successfully created.` : `Bond ${bondData.Bond.bondNumber} successfully updated.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            handleClose();
        } catch (err) {
            console.log(err);
            toast.error(modalType === 'form' ? `Error creating bond ${bondData.Bond.bondNumber}.` : `Error updating bond ${bondData.Bond.bondNumber}.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    const submitBond = async (e) => {
        e.preventDefault();

        setLoading(true);

        const bondData = {
            Bond: {
                caseIdFk: caseView.pkCaseId,
                incidentDate: state.incidentDate,
                incidentLocation: state.incidentLocation,
                postedLast: state.postedLast,
                postedFirst: state.postedFirst,
                postedPhone1: state.postedPhone1,
                postedAddress1: state.postedAddress1,
                postedAddress2: state.postedAddress2,
                postedCity: state.postedCity,
                postedState: state.postedState,
                postedZip: state.postedZip,
                bondAmount: parseFloat(state.bondAmount),
                bondNumber: state.bondNumber,
                bondDate: state.bondDate,
                currentStatute: state.statute,
                batchNumber: paymentBatchNumber,
                bondType: state.bondType
            },
            Payments:  null, 
            Statutes: selectedStatutes.map(statute => statute.id)
        }

        try {
            const res = modalType === 'form'
                ? await axios.post(`${apiRoute}/api/bond`, bondData)
                : await axios.put(`${apiRoute}/api/bond/${selectedBond.id}/${caseView.pkCaseId}`, { ...bondData, id: selectedBond.id })

            const newRows = res.data.map((row) => {
                const newRow = {
                    ...row.bond,
                    incidentDate: row.bond.incidentDate ? new Date(row.bond.incidentDate).toISOString().substring(0, 10) : null,
                    reimbursementDate: row.bond.reimbursementDate ? new Date(row.bond.reimbursementDate).toISOString().substring(0, 10) : null,
                    bondDate: row.bond.bondDate ? new Date(row.bond.bondDate).toISOString() : null,
                    appliedToCaseDate: row.bond.appliedToCaseDate ? new Date(row.bond.appliedToCaseDate).toISOString().substring(0, 10) : null,
                    remainingBondAmount: modalType === 'form' ? row.bond.bondAmount : row.bond.cashBondTransactions.reduce((remaining, transaction) => {
                        if (transaction.isActive) {
                            return remaining - transaction.transactionAmount;
                        }
                        return remaining
                    }, row.bond.bondAmount),
                    statuteIds: row.statuteIds
                }
                return newRow;
            })
            setBondRows(newRows);
            toast.success(modalType === 'form' ? `Bond ${bondData.Bond.bondNumber} successfully created.` : `Bond ${bondData.bondNumber} successfully updated.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            handleClose();
        } catch (err) {
            console.log(err);
            toast.error(modalType === 'form' ? `Error creating bond ${bondData.bondNumber}.` : `Error updating bond ${bondData.bondNumber}.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    }

    const [bondPayments, setBondPayments] = useState(
        [
            {
                paymentType: 'CASH',
                paymentRef: paymentStatute,
                amountReceived: '$0.00',
                paymentAmount: '$0.00',
                checkNumber: '',
                uuid: crypto.randomUUID(),
            }
        ]
    );

    const addBondPayment = () => {
        const payments = [...bondPayments];

        const newPaymentRow = {
            paymentType: 'CASH',
            paymentRef: paymentStatute,
            amountReceived: '$0.00',
            paymentAmount: '$0.00',
            checkNumber: '',
            uuid: crypto.randomUUID(),
        };

        // Add the new payment row to the state
        payments.push(newPaymentRow);
        setBondPayments([...payments]);
    };

    const handleDeleteRow = (index) => {
        const deletedPaymentAmount = bondPayments[index].paymentAmount;

        const updatedRows = [...bondPayments];
        updatedRows.splice(index, 1);

        setBondPayments(updatedRows);
    };

    const handlePaymentTypeChange = (e, payment) => {
        const selectedPaymentType = e.target.value;
        const indexByUUID = bondPayments.findIndex((row) => row.uuid === payment.uuid);
        const newRows = [...bondPayments];

        // Update the payment type for the current row
        newRows[indexByUUID].paymentType = selectedPaymentType;
        setBondPayments(newRows);
    };

    const tBackground = mode === 'dark' ? '#313131' : 'white';

    const grandTotal = bondPayments.reduce((acc, bp) => {
        acc += parseFloat(bp.paymentAmount) || '0.00';
        return parseFloat(acc);
    }, 0);

    const handleMouseDown = (payment) => {
        const input = amountRef.current.querySelector('input'); // Access the input field within the div
        if (input.value.startsWith("$")) {
            //input.value = input.value.substring(1); // Remove the "$" character
            input.focus(); // Focus the input field
            input.setSelectionRange(0, 0); // Set the selection range to the beginning
            const indexByUUID = bondPayments.findIndex((row) => row.uuid === payment.uuid);
            const updatedPayment = { ...payment, amountReceived: payment.amountReceived.slice(1,), paymentAmount: parseFloat(payment.amountReceived.slice(1,)) };
            const updatedPayments = [...bondPayments];
            updatedPayments[indexByUUID] = updatedPayment;
            setBondPayments(updatedPayments);
        }
    };

    useEffect(() => {
        setPaymentBatchNumber(openBatches[0]?.batchNumber)
    }, [])
    console.log('open batches', openBatches.sort((a, b) => a.batchNumber < b.batchNumber ? -1 : 1).filter(b => b.batchType === 'SIMPLE'));
    return (
        <form>
            <Paper sx={{ mb: '1vh' }} elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', height: '3vh', mb: '1vh', display:'flex' }} elevation={10}>
                    <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Charges Bond Is Attached To</h6>
                </Paper>
                <Box sx={{ maxHeight: '20vh', overflowY: 'auto' }}>
                    <table style={{ borderCollapse: 'separate', marginLeft: '8.5rem', textAlign: 'center' }}>
                        <thead>
                            <tr style={{ position: 'sticky', top: 0, backgroundColor: tBackground, zIndex: 1 }}>
                                <th style={{ paddingLeft: '1rem' }}>Charge Type</th>
                                <th style={{ paddingLeft: '2.5rem' }}>Statute</th>
                                <th style={{ paddingLeft: '4rem' }}>Description</th>
                                {/*<th style={{ paddingLeft: '1rem' }}>Balance</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {selectedStatutes?.map(({ id, chargeType, rsNumber, description, defaultFine }) => (
                                <tr key={id}>
                                    <td style={{ paddingLeft: '1rem' }}>{chargeType}</td>
                                    <td style={{ paddingLeft: '2.5rem' }}>{rsNumber}</td>
                                    <td style={{ paddingLeft: '4rem' }}>{description}</td>
                                    {/*<td style={{ paddingLeft: '1rem' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(defaultFine)}</td>*/}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
                <Box p={1}>
                    {state?.errors?.violationIdFk && (
                        <Typography variant="body2" color="error">
                            <strong>{state?.errors?.violationIdFk}</strong>
                        </Typography>
                    )}
                </Box>
            </Paper>
            <Paper sx={{ mb: '1vh', width: 'auto' }} elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', height: '3vh', display:'flex' }} elevation={10}>
                    <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Bond Info</h6>
                </Paper>
                <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', overflow: 'hidden' }}>
                    <h5 style={{ marginTop: '1vh', marginLeft: '1rem' }}>Batches: </h5>&nbsp;&nbsp;
                    <Box sx={{ width: '100%' }}>
                        <RadioGroup
                            row
                            name='searchFilter'
                            defaultValue={openBatches[0]?.batchNumber}
                            value={paymentBatchNumber}
                            onChange={(e) => setPaymentBatchNumber(e.target.value)}
                        >
                            {
                                // destructured batchNumber out of batch object to make this easier to read
                                openBatches.sort((a, b) => a.batchNumber < b.batchNumber ? -1 : 1).filter(b => b.batchType === 'SIMPLE').map(({ batchName, batchNumber }) => (
                                    <FormControlLabel
                                        key={batchNumber}
                                        value={batchNumber}
                                        control={<Radio />}
                                        label={batchName ? `${batchName}(${batchNumber})` : batchNumber}
                                    />
                                ))
                            }
                        </RadioGroup>
                    </Box>
                </Box>
                <Grid container direction="row" sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', p: '1.5vh 1vw' }}>
                    <Grid item sm={2.8}>
                        <TextField
                            fullWidth
                            required
                            sx={ts}
                            name="bondAmount"
                            id="bondAmount"
                            label="Bond Amount"
                            value={displayBondAmount}
                            onClick={() => modalType !== 'edit' && setDisplayBondAmount('')}
                            onChange={(e) => {
                                // Use a regular expression to validate the input as a dollar amount
                                const value = e.target.value.replace(/[^0-9.]/g, '');
                                if (!isNaN(value)) {
                                    handleFieldChange('bondAmount', value);
                                    setDisplayBondAmount(value);
                                }
                            }}
                            onBlur={(e) => {
                                const value = e.target.value.replace(/[^0-9.]/g, '');
                                if (value === '' || isNaN(value)) {
                                    handleFieldChange('bondAmount', '');
                                    setDisplayBondAmount('$0.00');
                                } else {
                                    setDisplayBondAmount(commaMoneyFormat(value));
                                }
                            }}
                            inputProps={{
                                step: '0.01',
                                style: { textAlign: 'right' },
                                inputMode: 'numeric',
                                readOnly: modalType === 'edit'
                            }}
                            InputProps={
                                {
                                    startAdornment:
                                        (
                                            modalType === 'edit' &&
                                            <InputAdornment position="start">
                                                <MdEditOff />
                                            </InputAdornment>
                                        )
                                }
                            }
                            helperText={state?.errors?.bondAmount}
                            error={!!state?.errors?.bondAmount}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={2.8}>
                        <TextField
                            fullWidth
                            required
                            sx={ts}
                            name="bondNumber"
                            id="bondNumber"
                            label="Bond #"
                            value={state.bondNumber || ''}
                            onChange={(e) => { handleFieldChange('bondNumber', e.target.value.replace(/[^a-zA-Z0-9-\s]/g, '')) }}
                            onBlur={(e) => { handleBlur('bondNumber', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            helperText={state?.errors?.bondNumber}
                            error={!!state?.errors?.bondNumber}
                            variant="outlined"
                            inputProps={{ readOnly: autoBondNum }}
                            InputProps={{
                                startAdornment: (autoBondNum &&
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item sm={2.8}>
                        <TextField
                            fullWidth
                            select
                            sx={ts}
                            name="bondType"
                            id="bondType"
                            label="Bond Type"
                            value={state.bondType || 'CASH BONDS'}
                            onChange={(e) => { handleFieldChange('bondType', e.target.value) }}
                            variant="outlined"
                            inputProps={{ readOnly: modalType === 'edit' }}
                            InputProps={{
                                startAdornment: (modalType === 'edit' && <InputAdornment position="start">
                                    <MdEditOff />
                                </InputAdornment>)
                            }}
                        >
                            {
                                bondTypeOptions?.map(p => (
                                    <MenuItem key={p} value={p} >{p}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item sm={2.8}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="checkNumber"
                            id="checkNumber"
                            label="Refund Check #"
                            value={state.checkNumber || ''}
                            onChange={(e) => { handleFieldChange('checkNumber', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={4.3}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="incidentLocation"
                            id="incidentLocation"
                            label="Incident Location"
                            value={state.incidentLocation || ''}
                            onChange={(e) => { handleFieldChange('incidentLocation', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            onBlur={(e) => {
                                const capitalizedValue = e.target.value
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ');
                                handleBlur('incidentLocation', capitalizedValue);
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={2.3}>
                        <TextField
                            fullWidth
                            required
                            sx={ts}
                            type="date"
                            name="bondDate"
                            id="bondDate"
                            label="Bond Date"
                            value={state.bondDate || ''}
                            onChange={(e) => { handleFieldChange('bondDate', e.target.value) }}
                            onBlur={(e) => { handleBlur('bondDate', e.target.value) }}
                            helperText={state?.errors?.bondDate}
                            error={!!state?.errors?.bondDate}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item sm={2.3}>
                        <TextField
                            fullWidth
                            sx={ts}
                            type="date"
                            name="incidentDate"
                            id="incidentDate"
                            label="Incident Date"
                            value={state.incidentDate || ''}
                            onChange={(e) => { handleFieldChange('incidentDate', e.target.value) }}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item sm={2.3}>
                        <TextField
                            fullWidth
                            sx={ts}
                            type="date"
                            name="reimbursementDate"
                            id="reimbursementDate"
                            label="Reimbursement Date"
                            value={state.reimbursementDate || ''}
                            onChange={(e) => { handleFieldChange('reimbursementDate', e.target.value) }}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ mb: '1.5vh' }} elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', height: 'auto', display:'flex' }} elevation={10}>
                    <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>{!!dataEntry ? 'Posted By' : 'Payments'}</h6>
                </Paper>
                {dataEntry ? <Grid container direction="row" sx={{
                    display: 'flex', gap: '1rem', flexWrap: 'wrap', p: '1.5vh 1vw'
                }}>
                    <Grid item sm={3.8}>
                        <TextField
                            fullWidth
                            required
                            sx={ts}
                            name="postedLast"
                            id="postedLast"
                            label="Last Name"
                            value={state.postedLast || ''}
                            onChange={(e) => { handleFieldChange('postedLast', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            onBlur={(e) => { handleBlur('postedLast', e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) }}
                            helperText={state?.errors?.postedLast}
                            error={!!state?.errors?.postedLast}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={3.8}>
                        <TextField
                            fullWidth
                            required
                            sx={ts}
                            name="postedFirst"
                            id="postedFirst"
                            label="First Name"
                            value={state.postedFirst || ''}
                            onChange={(e) => { handleFieldChange('postedFirst', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            onBlur={(e) => { handleBlur('postedFirst', e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) }}
                            helperText={state?.errors?.postedFirst}
                            error={!!state?.errors?.postedFirst}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={3.8}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="postedPhone1"
                            id="postedPhone1"
                            label="Phone"
                            value={displayPhone || ''}
                            onChange={(e) => {
                                const newPhoneNum = formatPhoneNumber(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
                                setDisplayPhone(newPhoneNum);
                                handleFieldChange('postedPhone1', cleanPhoneNumber(newPhoneNum))
                            }}
                            variant="outlined"
                            inputProps={{ maxLength: 14 }}
                        />
                    </Grid>
                    <Grid item sm={5.82}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="postedAddress1"
                            id="postedAddress1"
                            label="Address1"
                            value={state.postedAddress1 || ''}
                            onChange={(e) => { handleFieldChange('postedAddress1', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            onBlur={(e) => {
                                const capitalizedValue = e.target.value
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ');
                                handleBlur('postedAddress1', capitalizedValue);
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={5.82}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="postedAddress2"
                            id="postedAddress2"
                            label="Address2"
                            value={state.postedAddress2 || ''}
                            onChange={(e) => { handleFieldChange('postedAddress2', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            onBlur={(e) => {
                                const capitalizedValue = e.target.value
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ');
                                handleBlur('postedAddress2', capitalizedValue);
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={3.8}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="postedCity"
                            id="postedCity"
                            label="City"
                            value={state.postedCity || ''}
                            onChange={(e) => { handleFieldChange('postedCity', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            onBlur={(e) => {
                                const capitalizedValue = e.target.value
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ');
                                handleBlur('postedCity', capitalizedValue);
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={3.8}>
                        <TextField
                            fullWidth
                            select
                            sx={ts}
                            name="postedState"
                            id="postedState"
                            label="State"
                            value={state.postedState || ''}
                            onChange={(e) => { handleFieldChange('postedState', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            variant="outlined"
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: '20em',
                                        },
                                    },
                                },
                            }}
                        >
                            <MenuItem value=''>N/A</MenuItem>
                            {
                                stateOptions?.map(({ value, label }) => {
                                    return <MenuItem key={label} value={value}>{label}</MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item sm={3.8}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="postedZip"
                            id="postedZip"
                            label="ZIP Code"
                            value={state.postedZip || ''}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*$/.test(inputValue)) {
                                    handleFieldChange('postedZip', inputValue);
                                }
                            }}
                            onBlur={(e) => { handleBlur('postedZip', e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')) }}
                            helperText={state?.errors?.postedZip}
                            error={!!state?.errors?.postedZip}
                            variant="outlined"
                            inputProps={{ maxLength: 5 }}
                        />
                    </Grid>
                </Grid> :
                    <Grid container direction="row" sx={{ display: "inline-block", verticalAlign: 'middle' }}>
                        {bondPayments?.map((payment, index) => (
                            <React.Fragment key={payment?.uuid}>
                                <Box sx={{ display: "flex", alignItems: 'center', mt: "1vh" }}>
                                    <Grid item sm={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', height: '50px' }}>
                                            <Tooltip title="Delete Payment" placement="top">
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleDeleteRow(index)}
                                                    sx={{ backgroundColor: 'steelblue', color: 'white', m: 'auto', height: '100%', fontSize: '20px' }}
                                                >
                                                    <RiDeleteBinLine />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                sx={ts}
                                                select
                                                id="paymentType"
                                                label="Type"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                value={payment?.paymentType}
                                                onChange={(e) => handlePaymentTypeChange(e, payment)}
                                            >
                                                {
                                                    paymentOptions?.map((p) => {
                                                        return <MenuItem key={p} value={p}>{p}</MenuItem>;
                                                    })
                                                }
                                            </TextField>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={1.75}>
                                        <Box m={.5}>
                                            <TextField
                                                sx={ts}
                                                id="paymentRef"
                                                label="Reference"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                value={paymentStatute}
                                                inputProps={{ readOnly: true }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MdEditOff />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <div ref={amountRef}>
                                                <TextField
                                                    sx={ts}
                                                    id={`amountRecived-${index}`}
                                                    label="Amount Received"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{
                                                        style: { textAlign: 'right' },
                                                    }}
                                                    value={payment?.amountReceived}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.]/g, '');
                                                        if (!isNaN(value)) {
                                                            const indexByUUID = bondPayments.findIndex((row) => row.uuid === payment.uuid);
                                                            const updatedPayment = { ...payment, amountReceived: value.replace(/[^0-9.]/g, ''), paymentAmount: value.replace(/[^0-9.]/g, '') };
                                                            const updatedPayments = [...bondPayments];
                                                            updatedPayments[indexByUUID] = updatedPayment;
                                                            setBondPayments(updatedPayments);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.]/g, '');
                                                        const indexByUUID = bondPayments.findIndex((row) => row.uuid === payment.uuid);
                                                        if (!isNaN(value) || value !== '') {
                                                            const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(value);
                                                            const updatedPayment = { ...payment, amountReceived: formattedValue, paymentAmount: formattedValue.replace(/[^0-9.]/g, '') };
                                                            const updatedPayments = [...bondPayments];
                                                            updatedPayments[indexByUUID] = updatedPayment;
                                                            setBondPayments(updatedPayments);
                                                        }
                                                    }}
                                                    onMouseDown={() => { handleMouseDown(payment); }}
                                                />
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        {(payment?.paymentType === "MONEY ORDER" || payment?.paymentType === "CASHIERS CHECK") && (
                                            <Box m={.5}>
                                                <TextField
                                                    sx={ts}
                                                    required
                                                    id={`checkNumber-${index}`}
                                                    label="Check Number"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={payment?.checkNumber}
                                                    onChange={(e) => {
                                                        const indexByUUID = bondPayments.findIndex((row) => row.uuid === payment.uuid);
                                                        const newRows = [...bondPayments];
                                                        newRows[indexByUUID].checkNumber = e.target.value.replace(/[^0-9.]/g, '');
                                                        setBondPayments(newRows);
                                                    }}
                                                    onBlur={(e) => {
                                                        const indexByUUID = bondPayments.findIndex((row) => row.uuid === payment.uuid);
                                                        if (e.target.value === '') {
                                                            setErrors({
                                                                ...errors,
                                                                [indexByUUID]: 'Check Number Required'
                                                            })
                                                        } else {
                                                            const e = { ...errors };
                                                            delete e[indexByUUID];
                                                            setErrors(e);
                                                        }
                                                    }
                                                    }
                                                    helperText={!!errors?.[index] && errors?.[index]}
                                                    error={!!errors?.[index]}
                                                />
                                                {/*</Tooltip>*/}
                                            </Box>
                                        )}
                                    </Grid>
                                </Box>
                            </React.Fragment>))}
                        <Box sx={
                            {
                                display: "flex",
                                justifyContent: "space-between",
                                mt: "1vh",
                                alignItems: "flex-end",
                                mb: '.5vw'
                            }
                        }
                        >
                            <Box sx={
                                {
                                    width: "30%"
                                }
                            }
                            >
                                <Tooltip
                                    title="A payment type is needed on the payment before adding a new payment"
                                    placement="top"
                                    arrow
                                    disableHoverListener={!bondPayments.some((row) => row?.paymentType === '')}
                                >
                                    <span>
                                        <Button
                                            variant="contained"
                                            sx={
                                                {
                                                    m: ".5vh",
                                                    mt: "0",
                                                    width: '95%',
                                                    backgroundColor: 'steelblue',
                                                    color: 'white'
                                                }
                                            }
                                            onClick={addBondPayment}
                                            disabled={bondPayments.some((row) => row?.paymentType === '')}
                                        >
                                            Add Payment
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Box>
                            <TextField
                                sx={
                                    {
                                        ...ts,
                                        width: '25%',
                                        marginRight: '1vw'
                                    }
                                }
                                id="totalPayment"
                                label="Grand Total"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={
                                    {
                                        shrink: true
                                    }
                                }
                                value={new Intl.NumberFormat('en-US',
                                    {
                                        style: 'currency',
                                        currency: 'USD',
                                        maximumFractionDigits: 2
                                    })
                                    .format(grandTotal)}
                                inputProps={
                                    {
                                        readOnly: true
                                    }}
                                InputProps={
                                    {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MdEditOff />
                                            </InputAdornment>
                                        )
                                    }
                                }
                                helperText={grandTotal !== parseFloat(state.bondAmount) && "Grand Total must equal Bond Amount to submit payment"}
                                color={grandTotal !== parseFloat(state.bondAmount) ? 'warning' : 'success'}
                                focused={true}
                            />
                        </Box>
                    </Grid>
                }
            </Paper>
            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh' }}>
                {
                    (modalType === 'form' && dataEntry === true && state.bondType !== 'CASH BOND') ?
                        (
                            <Button
                                variant='contained'
                                sx={
                                    {
                                        backgroundColor: 'steelblue',
                                        color: 'white'
                                    }
                                }
                                onClick={
                                    (e) => {
                                        submitBond(e);
                                    }
                                }
                                disabled={!state.bondAmount || !state.bondNumber || !state.bondDate || !state.postedLast || !state.postedFirst || loading}
                                endIcon={<MdOutlineArrowForward />}
                            >
                                Submit Bond
                            </Button>
                        ) : (modalType === 'form' && dataEntry === true) ?
                            (
                                <Button
                                    variant='contained'
                                    sx={
                                        {
                                            backgroundColor: 'steelblue',
                                            color: 'white'
                                        }
                                    }
                                    onClick={
                                        (e) => {
                                            setDataEntry(false);
                                        }
                                    }
                                    disabled={!state.bondAmount || !state.bondNumber || !state.bondDate || !state.postedLast || !state.postedFirst || loading}
                                    endIcon={<MdOutlineArrowForward />}
                                >
                                    {modalType === 'form' ? 'Take Payment' : 'Edit Bond'}
                                </Button>
                            ) : (
                                <Button
                                    variant='contained'
                                    sx={
                                        {
                                            backgroundColor: 'steelblue',
                                            color: 'white',
                                            flex: 1,
                                            marginRight: 'auto',
                                            maxWidth: '7vw'
                                        }
                                    }
                                    onClick={
                                        (e) => {
                                            modalType === 'form' ? setDataEntry(false) : submitCashBond(e)
                                        }
                                    }
                                    disabled={loading}
                                    endIcon={modalType === 'form' && <MdOutlineArrowBack />}
                                >
                                    {modalType === 'form' ? 'Bond Info' : 'Save Bond'}
                                </Button>
                            )
                }
                {!dataEntry &&
                    <Button
                        variant='contained'
                        sx={
                            {
                                backgroundColor: 'steelblue',
                                color: 'white'
                            }
                        }
                        onClick={submitCashBond}
                        disabled={!state.bondAmount || !state.bondNumber || !state.bondDate || !state.postedLast ||
                            !state.postedFirst || loading || grandTotal !== parseFloat(state.bondAmount) ||
                            Object.keys(errors).length > 0 ||
                            bondPayments.some((bp) => {
                                return (bp.paymentType === 'CASHIERS CHECK' || bp.paymentType === 'MONEY ORDER') && bp.checkNumber === ''
                            })}
                    >
                        {modalType === 'form' ? 'Submit Payment(s)' : 'Edit Bond'}
                    </Button>
                }
                <Button
                    variant='contained'
                    sx={
                        {
                            backgroundColor: 'steelblue',
                            color: 'white'
                        }
                    }
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </Box>
        </form>
    )
};

export default BondsForm;